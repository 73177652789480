.description {
    margin-left: 20px;
}

.view {
    margin-left: 200px;
    margin-top: 60px;
    padding: 1px 16px;
}

@media screen and (max-width: 700px) {
    .view {
        margin-top: 20px;
        margin-left: 0;
        padding-left: 20px;
    }
}

@media screen and (max-height: 500px) {
    .view {
        margin-top: 30px;
    }
}
