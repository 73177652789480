.button {
    display: inline-block;
    border: none;
    background-color: inherit;
    font-size: 16px;
    outline: none;
    cursor: pointer;
}

button:active{
    color: #858585;
}

.status {
    margin-left: 18px;
    color: #858585;
}

.description {
    text-align: center;
}

.description p span {
    font-style: italic;
}

.buyButton {
    font-size: large;
    font-weight: bold;
    color: black;
    text-decoration: none;
}

.Gallery img {
    display: block;
    max-height: 60vh;
    width: 100%;
    object-fit: contain;
    margin-left: auto;
    margin-right: auto;
    pointer-events: none;
}

*:focus {
    outline: 0;
    outline: none;
}

.unselectable {
    user-select: none;
}

.clickable {
    cursor: pointer;
}