.contactContainer {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.subtitle {
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 10px;
}

.text a {
    color: black;
}

.mt-30 {
    margin-top: 300px;
}

.text {
    font-size: 20px;
    margin: 10px 0;
}

@media screen and (min-width: 900px) {
    .contactContainer{
        position: fixed;
        width: 500px;
        height: 200px;
        left: 0;
        right: 0;
        margin: 5% auto;
    }
}

@media screen and (max-width: 500px) {
    .contactContainer{
        margin-top: 0;
    }
}