.SocialLinksMenu {
    position: fixed;
    right: 0;
    bottom: 0;
    margin: 20px;
}

@media screen and (max-width: 700px) {
    .SocialLinksMenu {
        position: relative;
        float: right;
    }
}

@media screen and (max-height: 600px), (max-width: 850px) {
    .SocialLinksMenu {
        left: 0;
        bottom: 0;
        margin: 10px 20px;
    }
}

.icon {
    background-color: white;
    width: 30px;
    margin: 0 5px;
}
