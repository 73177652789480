.aboutContainer {
    margin-top: 40px;
    width: 60vw;
    font-size: 14px;
}

@media screen and (max-width: 700px) {
    .aboutContainer {
        margin-top: 0;
        width: 100%;
    }
}