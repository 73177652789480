.navbar {
    top: 0;
    margin: 0;
    padding-top: 80px;
    padding-left: 20px;
    width: 200px;
    position: fixed;
    height: 100%;
    overflow: auto;
}

.navbar a:not(name) {
    display: block;
    padding: 6px 8px 6px 8px;
    text-decoration: none;
    color: black;
}

.name {
    display: block;
    font-size: 47px;
    margin-bottom: 5px;
    font-family: Codec-Pro-Ultra-trial, serif;
}

@media screen and (max-width: 700px) {
    .navbar {
        padding-top: 20px;
        width: 90%;
        height: auto;
        position: relative;
    }
}

@media screen and (max-height: 500px) {
    .navbar {
        padding-top: 20px;
    }
}

.langBar {
    padding: 20px 8px 6px 8px;
}

input[type="radio"] {
    display: none;
}

input[type="radio"]:checked + label {
    font-weight: bold;
}

.langBar label {
    cursor: pointer;
}
